


const formNftsArr = (nfts_pictires, need_to_burn_nfts) => {
    const newArr = nfts_pictires.slice(0)


    for (let i = nfts_pictires.length; i < need_to_burn_nfts; i++) {
        newArr.push("")
        
    }

    return newArr
}

export const NftsContainer = ({nfts_pictires, need_to_burn_nfts}) => {

    const elementsArr = formNftsArr(nfts_pictires, need_to_burn_nfts)
    

    return (
    <>
        <div className="nfts_pictures_container">
            {elementsArr.map((picture, index) => (

                <div className="nft_picture" key={`nft_${index}`}>
                    {picture && (
                        <img src={picture} />
                    )}
                </div>

            ))

            }

        </div>
    </>
    )
}