import axios from "../axios";
import placeholder from "../img/imagePlaceholder.png"

export const getApiWallet = async () => {
    try {
        const {data} = await axios.get('/api/get_wallet/')
        if (data.wallet) {
            return data.wallet
        }

    } catch (error) {
        console.log(error)
        //return null
         return "UQCTVvPCU8fQ_g5Epu1_eRvf6I2izy9UWSpYbSuh6Yy5aQML";

    }
    
}



export const createTransaction = async (price) => {
    const api_wallet = await getApiWallet()
    if (!api_wallet)
         return null
    const transaction = {
        validUntil: Math.round(Date.now() / 1000) + 180,
        messages: [
            {
                address: api_wallet,
                amount: price * 1000000000,
            }
        ]
    }
    return transaction
    
}

export const addTransaction = async (transaction_hash, amount, wallet_address, event_id) => {
    try {
        const sending_json = {
            transaction_hash: transaction_hash, 
            amount: amount,
            wallet_address: wallet_address,
            event_id: event_id
        }
        console.log("addTransaction sending_json", sending_json)
        const {data} = await axios.post(`/api/add_transaction/`, sending_json)
        if (!data.transaction_id) {
            console.log("Error!", data.description)
            if (data.status === "DB_WRITING_ERROR") {
               
                return null
            }
            
            return null
        }
       
        return data.transaction_id
    } catch (error) {
        console.log(error)
        return null;
    }
}

export const getAvatar = async (subscription_link) => {
    try {
        const {data} = await axios.post(`/api/channel_avatar/`, {
            channel_url: subscription_link
        })
        return data.url
    } catch (error) {
        console.log(error)
        //return placeholder;
        return null
    }
}

export const getAvatarsArr = async (subscriptions) => {
    console.log("getAvatarsArr", subscriptions)
    var data = []
    for (let i=0; i<subscriptions.length; i++) {
        const channel = "https://t.me/" + subscriptions[i].substring(1, subscriptions[i].length);
        const avatar = await getAvatar(channel);
        data.push(avatar);
    }
    return data;
}


export const getTransactionStatus = async ({transaction_id, setTransactionStatus, addError, removeError} ) => {
    console.log("getTransactionStatus transaction_id:", transaction_id);
    if (!transaction_id) {
        return null
    }
    try {
        const {data} = await axios.post(`/api/transaction_status/`, {
            transaction_id: transaction_id,
           
        })
        if (!data.transaction_status) {
            if (data.status === "DB_READING_ERROR") {
                setTransactionStatus("error")
                console.log("Error!", data.description)
                return "error"
            }
             setTransactionStatus("loading")
             //addError("transaction", "Transaction in progress...", "loading")
            return "loading"          
            
        }
        switch (data.transaction_status) {
            case "new":
                setTransactionStatus("loading")
                //addError("transaction", "Transaction in progress...", "loading")
                return null
            case "success":
                setTransactionStatus("success")
                removeError("transaction")
                //addError("transaction", "Transaction completed successfully", "success")
                return "success"
            case "failed":
                setTransactionStatus("error")
                addError("transaction", "There was an error processing the transaction. Please try again", "error")
                return "error"
            case "canceled":
                setTransactionStatus("error")
                addError("transaction", "There was an error processing the transaction. Please try again", "error")
                return "error"
            case "crushed":
                setTransactionStatus("loading")
                return "loading"          
        
            default:
                setTransactionStatus("loading")
                //addError("transaction", "Transaction in progress...", "loading")
                return "loading" 
        }
       
    } catch (error) {
        console.log(error)
        addError("transaction", "There was an error processing the transaction. Please try again", "error")
        setTransactionStatus("error")
        return "error"
    }
}


export const getAuthorInfo = async (telegram_id, username) => {
    const sending_json = {
        telegram_id: telegram_id,
        username: username
    }
    try {
        const {data} = await axios.post(`/api/author_info/`, sending_json)
        if (data.status === "success") {
            return data.author_info
        }
        if (data.status === "NOT_FOUND") {
            return null
        }
        console.log("Error:",data.description);
        return null

       
    } catch (error) {    
        console.log(error)        
        return null  
        // var map = new Map()
        // map.set("collection_name", "Test777")
        // return map         
    }

}