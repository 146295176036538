import "./Checker.css"
import placeholder from "../img/imagePlaceholder.png"
import { useEffect, useState } from "react"
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { checkPassword, getUserInfo, sendDataToServer, setInitialParams } from "../utils/checkerConnections";
import { SubscriptionList } from "../components/Checker/SubscriptionList";
import { CountdownTimer } from "../components/Checker/CountdownTimer";
import { LoadingIndicator } from "../utils/appUtils";
import { PopupContainer } from "../components/General/PopupWindow";
import { IfWalletNotConnected, NoAccessPage } from "../components/General/IfWalletNotConnected";
import { LeaveCheckerWindow } from "../components/General/ModalWindow";


const formattedDatetime = (datetimeLocalString) => {
    // Преобразование строки в объект Date
    const date = new Date(datetimeLocalString);

    // Получение дня и месяца
    const day = String(date.getDate()).padStart(2, '0');    
    const month = String(date.getMonth() + 1).padStart(2, '0');

    // Форматируем результат
    return `${day}.${month}`
}





export const Checker = ({wallet, tonConnectUI, errorsMap, setErrorsMap, telegramId, username}) => {

    
    const navigator = useNavigate();
   
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [eventStatus, setEventStatus] = useState(null)

    const [passwordCheckedClass, setPasswordCheckedClass] = useState("")
    const [isGetInactive, setIsGetInactive] = useState(false)
    const [isCurrentEvent, setIsCurrentEvent] = useState(false)

   
   
    //const [messageForOverlay, setMessageForOverlay] = useState(null)
    const [timeEnd, setTimeEnd] = useState(false)



    const [nftStatus, setNftStatus] = useState(null)

    const [error, setError] = useState(null);

    const [openLeaveWindow, setOpenLeaveWindow] = useState(false)

    const [searchParams, setSearchParams] = useSearchParams();

    const [password, setPassword] = useState("");
    // const [invites, setInvites] = useState([""]);

    const event_id = searchParams.get("event_id") ? searchParams.get("event_id") : null;

    console.log("openLeaveWindow", openLeaveWindow)
    

    
   

    const [collectionParams, setCollectionParams] = useState({
        collection_name: "",
        event_name: "",
        description: "",
        logo: "",
        start_date: "",
        end_date: "",
        invites: 0,
        subscriptions: ["@rasfocuse", "@FarmerDao", "@channel1"],
        subscriptions_avatar_urls: [placeholder, placeholder, placeholder],
        minted_nfts: 9,
        nfts_cnt: 9,
        empty_password: false
    });


    
    const [selectedSubscriptions, setSelectedSubscriptions] = useState([])

    const [walletAddress, setWalletAddress] = useState(null)


    const addError = (errorType, errorMessage, errorStatus) => {
        setErrorsMap((prevErrors) => {
            // Создаем новый Map, копируя предыдущие ошибки
            const newErrorsMap = new Map(prevErrors);
            
            // Добавляем новую ошибку или обновляем существующую
            newErrorsMap.set(errorType, {
                status: errorStatus,
                message: errorMessage,
            });
            
            return newErrorsMap; // Возврат нового Map
        });
    };
    
    const removeError = (errorType) => {
        setErrorsMap(prevErrors => {
            const newErrorsMap = new Map(prevErrors);
            newErrorsMap.delete(errorType); // Удаление ошибки по типу
            return newErrorsMap; // Возврат обновленного Map
        });
    };

 
    const Overlay = () => {
        if (!timeEnd) {
            return null
        }
        return (
          <div className="overlay-window">
            <p class="overlay-window-message">this event is over</p>
          </div>
        );
      };

      useEffect(() => {
        setErrorsMap(new Map())
       
    }, []);
      

    useEffect(() => {
        setErrorsMap(new Map())
       
    }, []);

    useEffect(() => {
        const fetchData = async () => {

            setIsLoading(true); // начинаем загрузку
            try {
                //console.log("useEffect event_id", event_id)
                
                await setInitialParams({event_id, setCollectionParams, setEventStatus, setIsGetInactive, addError, setNftStatus});
                // if (!is_success) {
                //     return
                // }
                // if (!collectionParams.minted_nfts || !collectionParams.images_cnt) {
                //     setError(error);
                //     setEventStatus("Error receiving data")
                //     return
                // }
                // if (collectionParams.minted_nfts >= collectionParams.images_cnt) {
                //     setCntError("distributed")
                //     setIsGetInactive(true)
                //     return
                // }
                //console.log("fetchData", deactiveFields)
            } catch (error) {
                setError(error); // устанавливаем ошибку, если нужно
                setEventStatus("Error receiving data")
                console.log(error);
            } finally {
                setIsLoading(false); // завершили загрузку
            }
        };

        fetchData();
    }, [event_id]);

    
  

    useEffect(() => {
        if (isCurrentEvent && !errorsMap.get("nft") && !nftStatus) {
            setIsGetInactive(false)
        }
     }, [isCurrentEvent]);


    useEffect(() => {
        const fetchUserInfo = async () => {
            if (wallet) {
                const wallet_address = tonConnectUI.account.address; // Получаем адрес кошелька
                setWalletAddress(wallet_address)
                try {
                    const user_info = await getUserInfo(telegramId, username, event_id); 
                    // const user_info = {
                    //     visited_channels: ["@rasfocuse", "@FarmerDao", "@channel1"],
                    //     participated_events: []
                    // }
                    if (!user_info) {
                        setEventStatus("User not found")
                        return
                    }
                    
                    setSelectedSubscriptions(user_info.visited_channels)

                    const is_participated = user_info.participated 
                    if (is_participated) {
                        
                        addError("nft", "You have already received the nft from this event", "error")
                        setNftStatus(true)
                        setIsGetInactive(true)
                    }

                } catch (error) {
                    console.log(error)
                    setEventStatus("Server Error")
                    //  const participated_events = ["3"]
                    
                    //  if (participated_events.includes(event_id)) {
                    //     setReceivedError(true)
                    //     setIsGetInactive(true)
                    // }
                     //setEventStatus("Error getting user information")
                }
               
                

              
                
               
            }
        };

        fetchUserInfo(); // Вызов функции для получения информации о пользователе
    }, [wallet, tonConnectUI]);

    if (!wallet) return (
        <>
            <IfWalletNotConnected wallet={wallet} message={"Connect your wallet to see this event"} />
        </>
    )
    
    if (eventStatus) return (
        <>
             <NoAccessPage message={eventStatus} />
        </>
    )
   

    if (isLoading) {
       
        return <LoadingIndicator />;
    }

    if (error) {
        
        return <div className="dataLoading">Error: {error.message}</div>;
    }

    const getNftMessage = (status) => {

        switch (status) {
            case "success":
                return "Congratulations, the NFT has been sent to your wallet!"
            case "error":
                return "Server Error"
            case "NOT_FOUND":            
                return "Event not found"
            case "DB_READING_ERROR":
                return "Server Error"
            case "DB_WRITING_ERROR":
                return "Server Error"
            case "SERVER_ERROR":
                return "Server Error"                      
            case "EVENT_NFTS_LEFT":
                return "All the NFTs from this event have already been distributed"
            case "REPEAT_USER":
                return "You have already received the NFT from this event"
    
            case "MINT_ERROR":
                return "Error when trying to mint NFT into a collection"
    
            case "NFT_TRANSFER_ERROR":
                return "Error when trying to send the NFT"           
    
            default:
                return "We are sending the NFT..."
        }
    }

    const handleConnect = async (e) => {
        e.preventDefault()
        try {
            await tonConnectUI.openModal()
        } catch (error) {
            console.error('Failed to connect wallet:', error);
        }
    }; 

    const connect = async () => {
        try {
            await tonConnectUI.openModal()
        } catch (error) {
            console.error('Failed to connect wallet:', error);
        }
    }

  

    const handleSubmit = async (e) => {
        e.preventDefault();
  
        //console.log("handleSubmit collectionParams", collectionParams.subscriptions)
        //console.log("handleSubmit selectedSubscriptions", selectedSubscriptions)
      
        for (let i=0; i<collectionParams.subscriptions.length; i++) {
            const channel = collectionParams.subscriptions[i]
            //console.log("selectedSubscriptions.includes(channel) channel", selectedSubscriptions.includes(channel), channel)
            if (!selectedSubscriptions.includes(channel)) {
                
                addError("subscriptions", 'Subscribe to all channels!', "error")
                return
            }
        }
        removeError("subscriptions")

        // if (!invites || invites.length === 0) {
        //     alert('Choose your invites!')
        //     return
        // }
        // if (!checkSubscriptionsNew(invites)) {
        //     alert('Invalid invitations entered! Subscriptions must start with "@" and must not contain illegal characters!')
        //     return
        // }
        // const invites_arr = checkSubscriptionsNew(invites)


        // console.log("invites_arr", invites_arr)
        
        if (!collectionParams.empty_password) {

            const error = await checkPassword(event_id, password);
            //console.log("handleSubmit error password", error)
                //setPasswordCheckedClass
            if (error) {
                const message = error === "wrong" ? "Wrong password" : "Password verification error"
                addError("password", message, "error")
                setPasswordCheckedClass("error")
                // setIsGetInactive(true)
                return
            }
            removeError("password")
            setPasswordCheckedClass("success")
        }
        setIsGetInactive(true)
     
       
    
        const send_json = {
            event_id: event_id,
            wallet_address: tonConnectUI.account.address,
            telegram_id: telegramId
        }

       //setLoadingSendNftStatus("loading");
        addError("nft", getNftMessage("loading"), "loading")
        setNftStatus(true)
        // Вызов функции отправки данных
        const send_status = await sendDataToServer(send_json);
        if (send_status === "success") {
            setOpenLeaveWindow(true)
            removeError("nft")
        }
        else {
            addError("nft", getNftMessage(send_status), "error")
        }
       //console.log("send_status", send_status)
      
        // Сбрасываем состояние загрузки
        //setLoadingSendNftStatus(send_status);
        //console.log("setLoadingSendNftStatus", setLoadingSendNftStatus)
        
        
      
           
       


    };

  
  


    return (
        <>
        
      
            <div className="checker-box">
            

            
           
            <Overlay />

            <LeaveCheckerWindow isOpen={openLeaveWindow}/>
          
                 <div className="collection_description_container">
                <div className="collection_image_container info">
                    <img src={collectionParams.logo} alt="Collection" className="img" id="img" />
                </div>
                <div className="collection_summary">
                    <h1 className="collection_title">{collectionParams.collection_name}</h1>
                    <div className="collection_description">{collectionParams.event_name}</div>                 
                    
                </div>
               
            </div>
            <div className="collection_data">
                    <form className="collection_data_form" onSubmit={ async (e) => {
                           //setPassword(e.target[0].value) 
                         if (!wallet) {                       
                            await handleConnect(e);
                        }
                        else {

                            handleSubmit(e);
                        }
                       
                    } } >
                         {!collectionParams.empty_password && (
                            
                            <div className="form-item">
                                <label className="form-item-label">code</label>
                                <input className={`form-input ${isGetInactive ? "readonly-input" : ""} ${passwordCheckedClass}`} readOnly={isGetInactive} type="text" name="password" value={password} onChange={(e) => setPassword(e.target.value.toLowerCase())} maxLength={32} />
                            </div>
                            
                          )
                         }   
                       
                        <div className="form-item" style={{ marginBottom: '26px' }}>
                            <label className="form-item-label">subscriptions</label>
                            <SubscriptionList 
                                subscriptions={collectionParams.subscriptions} 
                                selectedSubscriptions={selectedSubscriptions}
                                setSelectedSubscriptions={setSelectedSubscriptions} 
                                avatarUrls = {collectionParams.subscriptions_avatar_urls}
                                telegramId = {telegramId}
                                readOnly={(nftStatus || timeEnd)}
                            />
                        </div>
                        
                        {/* <div className="form-item" style={{ marginBottom: '26px' }}>
                            <label className="form-item-label">invite</label>
                            
                            <InvitesInputBox invites={invites} setInvites={setInvites} />
                        </div> */}
                        <CountdownTimer start_date={collectionParams.start_date} end_date={collectionParams.end_date} setTimeEnd={setTimeEnd} nftStatus={nftStatus} setIsGetInactive={setIsGetInactive} setIsCurrentEvent={setIsCurrentEvent} timeZone={collectionParams.user_timezone}/>

                        {(isGetInactive) && (
                            <div className="submit-btn no-select collection-get-button get-btn-inactive" onClick={() => {
                                const copiedMap = new Map();
                                errorsMap.forEach((value, key) => {
                                    copiedMap.set(key, value);                                    
                                });
                                setErrorsMap(copiedMap)
                            }}>claim</div>
                        )                      

                        }
                        {/* {(isGetInactive) && (
                            <div className="submit-btn no-select collection-get-button get-btn-inactive">get</div>
                        )                      

                        } */}
                        {(!isGetInactive) && (
                            <input className={(wallet) ? "submit-btn no-select collection-get-button is-connect" : "submit-btn no-select collection-get-button"} type="submit" value="claim" />
                        )

                         } 
                    </form>
    
                    
                </div>
           
            </div>
        
        
      
        </>
    )

    


}