import { TonConnectButton } from "@tonconnect/ui-react"
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import logoPlaceholder from "../img/imagePlaceholder.png"
import { EventPreview } from "../components/General/EventPreview";
import { BurnCountdownTimer } from "../components/Burn/BurnCountdownTimer";
import { IfWalletNotConnected, NoAccessPage } from "../components/General/IfWalletNotConnected";
import "./Burn.css"
import picturePlaceholder from "../img/eventBackgroundDark.jpg"
import picturePlaceholderSecond from "../img/eventBackgroundLight.jpg"
import { NftsContainer } from "../components/Burn/NftsContainer";
import { TimeEndOverlay } from "../components/General/ModalWindow";

export const NftBurning = ({wallet, tonConnectUI, popupMessagesMap, setPopupMessagesMap, addPopupMessage, removePopupMessage, errorsInMessageMap, showMessagesAgain}) => {

    const [eventParams, setEventParams] = useState({
        collection_name: "My first NFT",
        event_name: "My first event",
        description: "I need NFT-token. It should be an orange smiley face",
        logo: logoPlaceholder,
        start_date: "2024-10-10T21:05",
        end_date: "2024-10-10T21:49",
        user_timezone: 7
    });

    const [userNftsInfo, setUserNftsInfo] = useState({
        need_to_burn_nfts: 20,
        nfts_count: 5
    })


    const nfts_pictires = [logoPlaceholder, picturePlaceholder, picturePlaceholderSecond, logoPlaceholder, picturePlaceholder]

    

    const [timeEnd, setTimeEnd] = useState(false)
    const [burnActive, setBurnActive] = useState(false)
    const [timeStop, setTimeStop] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(null)

    const [notStart, setNotStart] = useState(false)

    
    useEffect(() => {
        if (notStart || timeEnd || errorsInMessageMap()) {
            setBurnActive(false)
        }
        else {
            setBurnActive(true)
        }
    }, [notStart, timeEnd, popupMessagesMap]); 

    
    if (!wallet) return (
        <>
            <IfWalletNotConnected wallet={wallet} message={"Connect your wallet to burn NFTs"} />
        </>
    )
    
    if (showErrorMessage) return (
        <>
            <NoAccessPage message={showErrorMessage} />
        </>
    )

    const handleBurnClick = () => {
        if (!burnActive) {
            showMessagesAgain()
            return
        }
    }

    return (
        <>  
        <TimeEndOverlay timeEnd={timeEnd} />
         <EventPreview eventParams={eventParams}/>
        <div style={{marginBottom: "17px"}}>
                <span className="form-item-label">ptc to burn</span>
                <div className="nfts_info_container">
                    <p className="nfts_info_p">You have <span className="nfts_count_span">{userNftsInfo.nfts_count} NFT</span></p>
                    <NftsContainer nfts_pictires={nfts_pictires} need_to_burn_nfts={userNftsInfo.need_to_burn_nfts} />
                    <p>You need to burn <span className="nfts_count_span">{userNftsInfo.need_to_burn_nfts} NFT</span></p>

                 </div>   
                <BurnCountdownTimer start_date={eventParams.start_date} end_date={eventParams.end_date} timezone={eventParams.user_timezone} timeEnd={timeEnd} setTimeEnd={setTimeEnd} timeStop={timeStop} setNotStart={setNotStart} />
                

                
                <button className={`submit-btn no-select ${burnActive ? "is-connect" : "get-btn-inactive"}`} onClick={handleBurnClick}>burn</button>
            </div>

            <TonConnectButton className='tonConnectButton' style={wallet ? {display: "none"} : {display: "block"}}/>

        </>
    )
}