import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, Route, Routes, useNavigate, useSearchParams } from "react-router-dom"
import "./Minter.css"
import placeholder from "../img/loadImagePlaceholder.png"
import avatarPlaceholder from "../img/imagePlaceholder.png"
import { MinterFormTextValidator } from "../validators/minterFormsValidator";
import qrPlaceholder from "../img/qr-placeholder.jpg"
import NumberSelector, { TimeZoneSelector } from "../components/Minter/NumberSelector";
import DateTimeInput from "../components/Minter/DateTimeInput";
import { checkSubscription, checkSubscriptionsNew, getCurrentDatetimeLocal, isBase64Image } from "../utils/minterUtils";
import tonToUSD from "../utils/tonToUSD";
import { TonConnectButton } from "@tonconnect/ui-react";
import { getComission, getEventInfoForMinter, getRandomImage, sendDataToBot, sendMinterData } from "../utils/minterConnections";
import QRCode from "qrcode.react";
import { SubscriptionsInputBox } from "../components/Minter/SubscriptionsInputBox";
import { PopupFormErrorMessage, PopupMinter, PopupTransactionFailed, PopupConnectWallet, PopupTransactionProcess, PopupContainer } from "../components/General/PopupWindow";
import { addTransaction, createTransaction, getApiWallet, getAuthorInfo, getAvatar, getAvatarsArr, getTransactionStatus } from "../utils/generalConnections";
import { IfWalletNotConnected, NoAccessPage } from "../components/General/IfWalletNotConnected";
import TonWeb from "tonweb";
import { CheckboxComponent } from "../components/Minter/CheckboxComponent";
import { addElemToUseStateArr, getCurrentDate, removeElemFromUseStateArr } from "../utils/appUtils";
import { MakePostResultWindow, WarningModal } from "../components/General/ModalWindow";
import { DateTimeBox } from "../components/Minter/DateTimeBox";
import CalendarInput from "../components/Minter/CalendarInput";
import CalendarsBox from "../components/Minter/CalendarsBox";



const getFileUrl = file => new Promise((resolve, reject) => {

    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => {
        console.log(error);
        reject(null)
    };
});



export const Minter = ({wallet, tonConnectUI, telegramId, setErrorsMap, errorsMap, username}) => {

    const [searchParams, setSearchParams] = useSearchParams();
    //const minter_chat_id = searchParams.get("chat_id") ? searchParams.get("chat_id") : null;

    const navigate = useNavigate()
    const [eventId, setEventId] = useState(null)
    const [eventStatus, setEventStatus] = useState(null)
    const [timeZone, setTimeZone] = useState(3)
    const [closeAppWindowVisible, setCloseAppWindowVisible] = useState(false)
    
    const [errorIndices, setErrorIndices] = useState([]);
    const PORT = process.env.REACT_APP_API_URL.split(":")[2] ? `:${process.env.REACT_APP_API_URL.split(":")[2]}` : ""
    
    const [isSubscriptionsChecked, setSubscriptionsChecked] = useState(false)
    const [nameValidationError, setNameValidationError] = useState(false)

    
    

   // const initial_subscriptions = "@[channel1], @[channel2],..."

    const initial_subscriptions = ["@lidumapp"]

    const initialValues = {
        name: "",
        description: "",
        count: "count",
        start: "",
        end: "",
        password: "",
        subscriptions: initial_subscriptions,
        descriptionOfYourPost: "",
        button: "",
        file_name: "",
        file_base64: "",
    };

   const [comission, setComission] = useState(null)
   const [comissionToUSD, setComissionToUSD] = useState(null)
   const [apiWallet, setApiWallet] = useState(null)
   const descriptionRef = useRef(null);
   const descriptionOfYourPostRef = useRef(null); 
   const [eventName, setEventName] = useState(null)
   const [imageName, setImageName] = useState(null)
   const [notVerifiedSubscriptions, setNotVerifiedSubscriptions] = useState([])
   const [channelNotExistIndices, setChannelNotExistIndices] = useState([])
   const [channelInvalidFormatIndices, setChannelInvalidFormatIndices] = useState([])
   const [channelDublicateIndices, setChannelDublicateIndices] = useState([])
   const [successSubscriptions, setSuccessSubscriptions] = useState([])
   const [channelAvatars, setChannelAvatars] = useState(new Map())
   
   const [isQrChecked, setIsQrCheched] = useState(false)

   const [sendStatus, setSendStatus] = useState(null)
   const [formErrorMessage, setFormErrorMessage] = useState(null)

   const [readOnly, setReadOnly] = useState(false)
   

    const [pageNumber, setPageNumber] = useState(1)
    const [selectedFileUrl, setSelectedFileUrl] = useState(null)
    const [qrCodeLink, setQRCodeLink] = useState(null)

    const BOT = PORT === ":8080" ? "Ton_Application_Bot" : "lidum_bot"
    const APP_NAME = PORT === ":8080" ? "lidum_test" : "app"
    

    
    const [name, setName] = useState(initialValues.name)
    const [description, setDescription] = useState(initialValues.description)
    const [count, setCount] = useState(initialValues.count)
    const [start, setStart] = useState(initialValues.start)
    const [end, setEnd] = useState(initialValues.end)
    const [password, setPassword] = useState(initialValues.password)
    const [subscriptions, setSubscriptions] = useState(initialValues.subscriptions)
    // const [invite, setInvite] = useState(initialValues.invite)
    const [descriptionOfYourPost, setDescriptionOfYourPost] = useState(initialValues.descriptionOfYourPost)
    const [button, setButton] = useState(initialValues.button)
    const [isTransactionError, setIsTransactionError] = useState(null)
    const [transactionId, setTransactionId] = useState(null)
    const [intervalId, setIntervalId] = useState(null);

    const [transactionStatus, setTransactionStatus] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);

    // setErrorsMap(new Map())
    

    useEffect(() => {
        if (descriptionRef.current) {
            descriptionRef.current.style.height = 'auto'; // Сбрасываем высоту
            descriptionRef.current.style.height = `${descriptionRef.current.scrollHeight}px`; // Устанавливаем высоту на основе содержимого
        }
        if (descriptionOfYourPostRef.current) {
            descriptionOfYourPostRef.current.style.height = 'auto'; // Сбрасываем высоту
            descriptionOfYourPostRef.current.style.height = `${descriptionOfYourPostRef.current.scrollHeight}px`; // Устанавливаем высоту на основе содержимого
        }
    }, [description, descriptionOfYourPost]);



    useEffect(() => {
        setErrorsMap(new Map())
       
    }, []);

    useEffect(() => {
        if (transactionStatus === "error") {
            setIsTransactionError(true)
        }
        else {
            setIsTransactionError(false)
        }
       
    }, [transactionStatus]);
    
    useEffect(() => {

        const fetchData = async () => {
            if (!searchParams.get("event_id")) {
                const newChannelAvatars = new Map()
                try {
                    const avatarUrl = await getAvatar("@lidumapp")
                    if (avatarUrl) {                       
                        newChannelAvatars.set(0, avatarUrl)
                    }
                    else {
                        newChannelAvatars.set(0, avatarPlaceholder)
                    }
                    setChannelAvatars(newChannelAvatars)
                } catch (error) {
                    console.log(error)
                    newChannelAvatars.set(0, avatarPlaceholder)
                    setChannelAvatars(newChannelAvatars)
                }
                return
            }           
            try {
                const event_id = searchParams.get("event_id")
                setTransactionStatus("success")
                const data = await getEventInfoForMinter(event_id)
                setEventId(event_id)
                setCount(data.nfts_cnt)
                setEventName(data.event_name)
                setName(data.collection_name)
                setDescription(data.description)
                setSelectedFileUrl(data.logo)
                setStart(data.start_date)
                setEnd(data.end_date)          
                setImageName(data.image_name)    
                setTimeZone(data.user_timezone) 
                const avatars_url = await getAvatarsArr(data.subscriptions)
                const newChannelAvatars = new Map()
                const newSubscriptions = []
                for (let i=0; i<avatars_url.length; i++) {
                    if (avatars_url[i]) {
                        newChannelAvatars.set(i, avatars_url[i])
                        newSubscriptions.push(data.subscriptions[i])
                    }
                    
                }
                setSubscriptions(newSubscriptions)
                //console.log("newChannelAvatars", newChannelAvatars)
                setChannelAvatars(newChannelAvatars)
                
            } catch (error) {
                console.log(error)
                setEventStatus("Event not found")
                // setSubscriptions(["@lidumapp"])
                // const newChannelAvatars = new Map()
                // newChannelAvatars.get(0, )
            }

            // collection_name: "Collection Name",
            // event_name: "Event Name",
            // description: "123123123",
            // logo: placeholder,
            // start_date: `2024-09-14T02:30`,
            // end_date: `2024-09-18T18:39`,
            // invites: 0,
            // subscriptions: ["@rasfocuse", "@FarmerDao", "@channel1"],
            // subscriptions_avatar_urls: [placeholder, placeholder, placeholder],
            // nfts_cnt: 9,
        };

        fetchData();
       
    }, []);



   
    
    const handleComission = async (collection_cnt) => {

        const result = await getComission({
            telegram_id: telegramId,
            collection_images_cnt: Number(collection_cnt)
        })
        if (result) {
            setComission(result)
            const response = await tonToUSD(result);
            if (response) {
                setComissionToUSD(response)
            }
        }
        
    }
    const handleConnect = async () => {
        try {
            await tonConnectUI.openModal()
        } catch (error) {
            console.error('Failed to connect wallet:', error);
        }
    };

   



    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        

        if (file) {
           
            
            const fullFileUrl = await getFileUrl(file)

            if (fullFileUrl) {
                const extension = getBase64ImageExtention(fullFileUrl)
                if (!extension) {
                    console.log("Неверный тип расширения")
                    setSelectedFileUrl(null)
                    addError("image", "Invalid extension type! Select an image in png or jpg format", "error")
                    return null
                }
                if (errorsMap.get("image")) {
                    removeError("image")
                }
              
                console.log("handleFileChange extention", extension)
                console.log(`usr_img_${getCurrentDatetimeLocal()}.${extension}`)
                setImageName(`usr_img_${getCurrentDatetimeLocal()}.${extension}`)
                setSelectedFileUrl(fullFileUrl)
            }
            
           
            return fullFileUrl
        }
        else {
            return null
        }

    }

    const LoadImage = () => {

        return (
            <div className="load-file-container">
                <div className="collection_image_container">

                    <img src={selectedFileUrl ? selectedFileUrl : placeholder} alt="Collection" className="img" id="loaded_img" />

                </div>
                <input type="file" id="file_input" className="load_img" accept="image/*" onChange={async (e) => {
                    const fileUrl = await handleFileChange(e)
                    
                    if (fileUrl) {
                      
                        document.getElementById("loaded_img").src = fileUrl;
                        
                    }


                }
                } />
            </div>
        )
    }

    const ArrowBox = ({ pageNumber }) => {

        return (

            <div className="arrow-box">
                <BackArrowButton pageNumber={pageNumber} />
                <NextArrowButton pageNumber={pageNumber} />
            </div>

        )
    }

    const addNotVerifiedSubscription = (index) => {
        addElemToUseStateArr({ setArr: setNotVerifiedSubscriptions, elem: index });
    };

    const removeNotVerifiedSubscription = (index) => {     
        if (!notVerifiedSubscriptions.includes(index)) {
            return
        }
        const newVerifiedSubscriptions = notVerifiedSubscriptions.filter(item => item !== index);
        setNotVerifiedSubscriptions(newVerifiedSubscriptions)
    };

    

    const addSubscription = (newSubscription) => {
        addElemToUseStateArr({ setArr: setSubscriptions, elem: newSubscription });
    };

    const removeSubscription = (indexToRemove) => {
        removeElemFromUseStateArr({ setArr: setSubscriptions, arr: subscriptions, index_1: indexToRemove });
        setErrorIndices((prev) => prev.filter((index) => index !== indexToRemove));
    };

  
    const replaceSubscription = (newValue, index) => {
        const newSubscriptions = [...subscriptions]; // Создаем новый массив
        newSubscriptions[index] = newValue; // Обновляем подписку по индексу
        setSubscriptions(newSubscriptions); // Устанавливаем новое значение массива
    };

    const handleSubscriptionBlur = async (value, index) => {
        const idx = parseInt(index, 10);      
        
        const errors = []
        if (idx >= subscriptions.length) {
            return false; // Выходим из функции, если индекс некорректный
        }

            const formatted_subscription = checkSubscription(value);
    
            if (!formatted_subscription) {
                errors.push(idx)
                setErrorIndices((prev) => [...prev, idx]);
                console.log("Неверный формат.");
                //setErrorIndices((prev) => [...new Set([...prev, ...errors])]);
                return errors;
            }
           
            

            const countValue = subscriptions.filter(item => item === formatted_subscription).length;
            
            
            if (countValue > 1) {
                // const lastIndex = subscriptions.lastIndexOf(formatted_subscription)
                // if (!errorIndices.includes(lastIndex)) {
                //     setErrorIndices((prev) => [...prev, lastIndex]);
                // }   
                setErrorIndices((prev) => [...prev, idx]);
                errors.push(idx)        
                return errors
            }
            replaceSubscription(formatted_subscription, idx);
            
            const avatarUrl = await getAvatar(formatted_subscription);
            // const avatarUrl = true
            if (!avatarUrl) {
                errors.push(idx) 
                setErrorIndices((prev) => [...prev, idx]);
                console.log("Канал не найден. Пожалуйста, попробуйте снова.");
                return errors
            } else {
                removeNotVerifiedSubscription(idx)
               
               
                return false
            }


          
    }


//     const handleSubscriptionsVerified = async () => {
//     if (notVerifiedSubscriptions.length === 0) {
//         return true;
//     }

//     // Перебираем все подписки и проверяем их
//     const verificationPromises = notVerifiedSubscriptions.map(async (idx) => {
//         const value = subscriptions[idx];
//         await handleSubscriptionBlur(value, idx);
//     });

//     // Ждем завершения всех промисов
//     await Promise.all(verificationPromises);

//     // Проверяем есть ли ошибки
//     if (errorIndices.length > 0) {
//         // Возвращаем false, если есть ошибки
//         return false;
//     }
    
//     return true;
// }
    // const handleSubscriptionsVerified = async () => {
    //     console.log("handleSubscriptionsVerified")
    //     var newErrors = []
    //     if (notVerifiedSubscriptions.length === 0) {
    //         return true
    //     }
    //     for (let i = 0; i < notVerifiedSubscriptions.length; i++) {
    //         const idx = notVerifiedSubscriptions[i]
    //         const value = subscriptions[idx]
    //         const is_error = await handleSubscriptionBlur(value, idx)
    //         if (is_error) {
    //             newErrors.push(idx)
    //         }
    //     }
    //     console.log("handleSubscriptionsVerified", newErrors)

    //     if (newErrors.length > 0) {

    //        // setFormErrorMessage('Subscriptions input error! Channels do not exist or are repeated')
    //         // console.log("Форма содержит ошибки и не может быть отправлена.");
    //         return false; // Не отправляем форму, если есть ошибки
    //     }
    //     return true
    // }
    function findLastDuplicateIndex(arr) {
        const seen = new Set();
        let lastIndex = -1;
    
        for (let i = arr.length - 1; i >= 0; i--) {
            if (seen.has(arr[i])) {
                lastIndex = i; // Запоминаем индекс последнего дубликата
                break; // Выходим из цикла, если нашли дубликат
            }
            seen.add(arr[i]);
        }
    
        return lastIndex; // Вернем индекс последнего дублирующегося элемента
    }

    const onSubscriptionFocus = (index) => {
        // Удаляем индекс ошибки из состояния, если он есть
        setErrorIndices((prev) => prev.filter((i) => i !== index));
        setChannelInvalidFormatIndices((prev) => prev.filter((i) => i !== index));
        setChannelNotExistIndices((prev) => prev.filter((i) => i !== index));
        setChannelDublicateIndices((prev) => prev.filter((i) => i !== index));
        setSuccessSubscriptions((prev) => prev.filter((i) => i !== index))
        if (channelAvatars.get(index)) {
            removeChannelAvatar(index)
        }
        
    };

    const handleSubmitSubscriptions = async () => {
        const newErrorIndices = [];
        const newChannelNotExistIndices = [];
        const newChannelDublicateIndices = [];
        const newChannelInvalidFormatIndices = [];
        const newSuccessSubscriptions = []

        const newChannelAvatars = new Map()

        setSubscriptionsChecked(true)

        
    for (let index = 0; index < subscriptions.length; index++) {
        const value = subscriptions[index];
        
        const formatted_subscription = checkSubscription(value);

        if (!formatted_subscription) {
            newErrorIndices.push(index);
            newChannelInvalidFormatIndices.push(index)
        }
        else {
            
               
           

         const avatarUrl = await getAvatar(formatted_subscription); // Проверка существования канала
            //const avatarUrl = true
            if (!avatarUrl) {
                newErrorIndices.push(index);
                newChannelNotExistIndices.push(index)
                continue
            }
            newChannelAvatars.set(index, avatarUrl)
            const countValue = subscriptions.filter(item => item === formatted_subscription).length;
            if (countValue > 1) {
                setErrorIndices((prev) => [...prev, index]);
                newErrorIndices.push(index)    
                newChannelDublicateIndices.push(index)
            }
            else {
                newSuccessSubscriptions.push(index)
            }

            const newSubscriptions = [...subscriptions]; // Создаем новый массив
            newSubscriptions[index] = formatted_subscription; // Обновляем подписку по индексу
            setSubscriptions(newSubscriptions); // Устанавливаем новое значение массива
        }        
    }
        setSuccessSubscriptions(newSuccessSubscriptions)
        setErrorIndices(newErrorIndices);
            setChannelDublicateIndices(newChannelDublicateIndices)
            setChannelNotExistIndices(newChannelNotExistIndices)
            setChannelInvalidFormatIndices(newChannelInvalidFormatIndices)
        setChannelAvatars(newChannelAvatars)
        // Если есть ошибки, обновляем состояние errorIndices
        if (newErrorIndices.length > 0) {
            
            //setFormErrorMessage('Subscriptions input error! Channels do not exist or are repeated')
            return false; // Не отправляем форму, если есть ошибки
        }
        return true
    }

    const handleSubmit = async (e, pageNumber) => {
        
   
        e.preventDefault();
       
        var newErrors = new Map()

        
        if (!selectedFileUrl) {            
            //setFormErrorMessage("Select an image!")
            newErrors.set("image", {
                status: "error",
                message: "Select an image!"
            })
 
        }
        if (nameValidationError) {
            //setFormErrorMessage("Collection name validation error! Only English letters are allowed")
            newErrors.set("name", {
                status: "error",
                message: "Collection name validation error! Only English letters are allowed"
            })
                    
        }
        if (pageNumber === 2) {
            
         
            
            const isCorrectSubscriptions = await handleSubmitSubscriptions()
            if (!isCorrectSubscriptions) {

                newErrors.set("subscriptions", {
                    status: "error",
                    message: "Subscriptions input error! Channels do not exist or are repeated"
                })
    
              
                
            }

            if (!start || !end) {
               
               newErrors.set("noDate", {
                status: "error",
                message: "No start or end date specified!"
            })

                
            }

            

            const startDate = new Date(start);
            const endDate = new Date(end);
            const currentDate = getCurrentDate(timeZone)
            if (startDate < currentDate) {
               

                newErrors.set("startDate", {
                    status: "error",
                    message: "The start date must be later than the current time!"
                })
                
            }
            if (endDate <= startDate) { 
                
                newErrors.set("endDate", {
                    status: "error",
                    message: "The start date is greater than the end date!"
                })
                
                
            }
           
            if (newErrors.size > 0) {
                setErrorsMap(newErrors)
                setTimeout(() => {
                    setErrorsMap(new Map()); // Очистка ошибок через 3 секунды
                }, 3000);
                return
            }
            setErrorsMap(new Map())


            await sendDataToServer()
        }
        else {
            if (newErrors.size > 0) {
                setErrorsMap(newErrors)
                setTimeout(() => {
                    setErrorsMap(new Map()); // Очистка ошибок через 3 секунды
                }, 3000);
                return
            }
            setErrorsMap(new Map())
            if (pageNumber !== pagesArr.length - 1) {
                setPageNumber(pageNumber + 1)
            }
        } 
        
       

    };


    const handleFocusCount = (e) => {
   
        document.getElementById("count").classList.remove("check-input")
        if (e.target.value === "count") {
            setCount(""); 
        }
    };

    const handleBlurCount = (e) => {

        document.getElementById("count").classList.add("check-input")
        if (e.target.value === "") {
            setCount("count");
        }
    };

    const handleBlurInput = (elem_id) => {
        document.getElementById(elem_id).classList.add("check-input")
    }

    const handleFocusInput = (elem_id) => {
        document.getElementById(elem_id).classList.remove("check-input")
    }

   

    const handleAddTransaction = async (transaction_hash, event_id) => {
        const wallet_address = tonConnectUI.account.address
        const transaction_id = await addTransaction(transaction_hash, comission, wallet_address, event_id)
        if (transaction_id) {
            setTransactionId(transaction_id)
            return transaction_id
        }
        
        return null
    }

    const handleCreateTransaction = async (event_id) => {
        try {
           
            const transaction = await createTransaction(comission)
            
            const response = await tonConnectUI.sendTransaction(transaction);
            if (!response || !response.boc) {
                throw new Error("Не удалось получить ответ от блока");
            }
            
            const boc_cell = await TonWeb.boc.Cell.oneFromBoc(TonWeb.utils.base64ToBytes(response.boc)).hash();  
           
            const transaction_hash = TonWeb.utils.bytesToBase64(boc_cell);
           
            setTransactionStatus("loading")
            addError("transaction", "Transaction in progress...", "loading")
            const transaction_id = await handleAddTransaction(transaction_hash, event_id)
            
            if (!transaction_id) {
                setTransactionStatus("error")  
                addError("transaction", "There was an error processing the transaction. Please try again", "error")           
                return null
            }
            return transaction_id
        } catch (error) {
            setTransactionStatus("error")
            addError("transaction", "There was an error processing the transaction. Please try again", "error")           
           
            return null
        }


    }  
    
    const waitForTransactionSuccess = (transaction_id) => {
        return new Promise((resolve, reject) => {
            const id = setInterval(() => {
                getTransactionStatus({ transaction_id, setTransactionStatus, addError, removeError }).then(status => {
                    if (status === "success") {
                        clearInterval(id);
                        setIntervalId(null)
                        resolve("success");
                    } else if (status === "error") {
                        clearInterval(id);
                        setIntervalId(null)
                        reject(new Error("Transaction failed"));
                    }
                });
            }, 5000);
        });
    }

    const getBase64ImageExtention = (base64Image) => {
        const [header, base64Data] = base64Image.split(',');

       
        if (header.startsWith('data:')) {
            const mimeType = header.match(/:(.*?);/)[1]; // Извлекаем MIME-тип

            let extension;

            switch (mimeType) {
                case 'image/png':
                    extension = 'png';
                    break;
                case 'image/jpeg':
                    extension = 'jpg';
                    break;
                default:
                    extension = '';
                    break;
            }

               
                return extension;
            } else {
                //console.log('Не удалось определить тип данных.');
                return "";
            }
    }

    
    const handleRandomGen = async () => {
        
        const image = await getRandomImage()
       

        if (image)
        {
            const extension = getBase64ImageExtention(image)
            setSelectedFileUrl(image)
            setImageName(`rnd_nft_${getCurrentDatetimeLocal()}.${extension}`)
            document.getElementById("loaded_img").src = image;
            
        }
    }

    const MinterFirstPage = () => {
        console.log("PORT BOT", PORT, BOT)
        return (
            <>
                  
            <div>
                <LoadImage />

                <div className="btn-box">
                    <button className="btn no-select" onClick={handleRandomGen}>random gen</button>
                    {/* <button className="btn no-select">ai gen</button> */}
                </div>
                <div className="form-box">
                    <form className="minter-form" onSubmit={async (e) => {
                        e.preventDefault()
                    if (!wallet) {
                       
                            await handleConnect();
                        }
                        else {
                            await handleSubmit(e, pageNumber, {
                                collection_name: name,
                                event_name: eventName,
                                description: description,
                                count: count
                            })
                            if (transactionStatus !== "success") {
                                await handleComission(count);
                            }
                            else {
                                setComission(0)
                                setComissionToUSD(0)
                            }
                           
                        }
                        
                    
                    

                    }
                    
                   
                    
                    }

                    
                        
                    >

                        <div className="form-item">
                            <label className="form-item-label" >collection name</label>
                            <input className={`form-input ${(readOnly || eventId) ? "readonly-input" : "" }`} id="collection_name" type="text" name="name" 
                            onChange={
                                (e) => {
                                    const pattern = /^[A-Za-z0-9\s-_,.!?'"() @#$%^&*]*$/;
                                    setName(e.target.value)
                                    document.getElementById("collection_name").classList.add("check-input")
                                    if (!pattern.test(e.target.value)) {
                                       
                                        setNameValidationError(true)
                                        document.getElementById("collection_name").classList.add("error")
                                        document.getElementById("collection_name").classList.remove("success")
                                        return
                                    }
                                    setNameValidationError(false)
                                    if (errorsMap.get("name")) {
                                        removeError("name")
                                    }
                                    
                                    document.getElementById("collection_name").classList.remove("error")
                                        document.getElementById("collection_name").classList.add("success")
                                    
                                    }
                                
                                
                                } defaultValue={name ? name : ""} required maxLength={64} readOnly={(readOnly || eventId) ? true: false} 
                                
                                onFocus={() => {
                                    document.getElementById("collection_name").classList.remove("check-input")
                                    document.getElementById("collection_name").classList.remove("error")
                                        document.getElementById("collection_name").classList.remove("success")
                                }}
                                
                                  />
                        </div>
                        <div className="form-item">
                            <label className="form-item-label">event name</label>
                            <input className={`form-input ${eventId ? "readonly-input" : ""}`} type="text" name="event_name" readOnly={eventId ? true : false} onChange={(e) => setEventName(e.target.value)} id="event_name" onBlur={() => handleBlurInput("event_name")} defaultValue={eventName ? eventName : ""} required maxLength={64} />
                        </div>
                        <div className="form-item margin-bottom-17">
                            <label className="form-item-label">description</label>
                            <textarea ref={descriptionRef} className="form-input textarea" id="description" onBlur={() => handleBlurInput("description")} type="text" name="description" onChange={(e) => {

                            
                          
                            setDescription(e.target.value)
                                                        }
                          
                            
                            } rows={2} value={description ? description : ""} required maxLength={256}  ></textarea>
                        </div>                        
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "17px"}}>                           
                            <input name="count" id="count" className={`btn input-count-btn ${eventId ? "readonly-input" : ""}`} readOnly={eventId ? true : false}  pattern="\d*" type="text" onChange={(e) => setCount(e.target.value)} isSubscriptionsChecked={isSubscriptionsChecked} value={count} onFocus={handleFocusCount} onBlur={handleBlurCount} min={1} required />
                            <CheckboxComponent isChecked={isQrChecked} setIsChecked={setIsQrCheched}/>
                        </div>
                        <div className="arrow-box">
                            <ArrowBox pageNumber={1} />
                        </div>
                    </form>
                </div>


            </div>
            </>
        )
    }

 
   

        const handleSendToBot = async (e, descriptionOfYourPost, button) => {
            e.preventDefault()
            const sendingData = {
                qrcode: getQRCodeBase64Image(),
                description: descriptionOfYourPost,
                button: button,
                button_url: qrCodeLink,
                telegram_id: telegramId,
            }
 
           const is_send = await sendDataToBot(sendingData)
           if (is_send) {
            setCloseAppWindowVisible(true)
            //removeError("createPost")
           }
           else {
            addError("createPost", "Error creating post. Please try again", "error")
           }
            
        }


        const QRCodeGenerator = ({link}) => {           
           
        console.log("QRCodeGenerator link", link)
            
            return (
              <div className="qr-container">
              
                <QRCode value={link} size={200} id="qr-code-canvas" renderAs="canvas"/>
              </div>
            );
          };

          
          const getQRCodeBase64Image = () => {
            const canvas = document.getElementById("qr-code-canvas");
            const dataURL = canvas.toDataURL("image/png");
            return dataURL;
          };
        

        

    const MinterThirdPage = () => {
       
        return (
            <>
            <MakePostResultWindow isOpen={closeAppWindowVisible}/>
            <QRCodeGenerator link={qrCodeLink}/>
                
                <div className="form-box">
                    <form className="minter-form" onSubmit={ async (e) => {
                        await handleSendToBot(e, e.target[0].value, e.target[1].value)
                
                }}>

                        <div className="form-item">
                            <label className="form-item-label">description of your post</label>
                            <textarea ref={descriptionOfYourPostRef} className="form-input textarea" rows={2} type="text" name="descriptionOfYourPost" onChange={(e) => setDescriptionOfYourPost(e.target.value)} defaultValue={descriptionOfYourPost ? descriptionOfYourPost : ""} required></textarea>
                        </div>
                        <div className="form-item margin-bottom-17">
                            <label className="form-item-label">button</label>
                            <input className="form-input" type="text" name="button" onChange={(e) => setButton(e.target.value)} value={button ? button : ""} required maxLength={256} />
                        </div>
                        <input className="btn send-to-bot-btn no-select" type="submit" value="send to bot" />
                        <div className="arrow-box">
                            <ArrowBox pageNumber={3} />
                        </div>
                    </form>
                </div>
            </>
        )
    }
    

    const addError = (errorType, errorMessage, errorStatus) => {
        setErrorsMap((prevErrors) => {
            // Создаем новый Map, копируя предыдущие ошибки
            const newErrorsMap = new Map(prevErrors);
            
            // Добавляем новую ошибку или обновляем существующую
            newErrorsMap.set(errorType, {
                status: errorStatus,
                message: errorMessage,
            });
            
            return newErrorsMap; // Возврат нового Map
        });
    };
    
    const removeError = (errorType) => {
        setErrorsMap(prevErrors => {
            const newErrorsMap = new Map(prevErrors);
            newErrorsMap.delete(errorType); // Удаление ошибки по типу
            return newErrorsMap; // Возврат обновленного Map
        });
    };


    const removeChannelAvatar = (index) => {
        setChannelAvatars(prevErrors => {
            const newMap = new Map(prevErrors);
            newMap.delete(index); 
            return newMap; // Возврат обновленного Map
        });
    };

    const sendDataToCreatePost = async () => {
        const minterData = {
            price: comission,
            collection_name: name,
            event_name: eventName,
            event_description: description,
            nfts_cnt: Number(count),
            image_name: imageName,
            image: selectedFileUrl,
            start_date: start,
            end_date: end,
            password: password,
            subscriptions: subscriptions.join(),
            wallet_address: tonConnectUI.account.address,
            event_id: eventId,
            telegram_id: telegramId,
            user_timezone: timeZone
        };
        // setErrorIndices((prev) => [...prev, idx]);
        //const message = status === "loading" ? "We are creating your event..." : "Error sending data! Please, try again later"
       
        //setErrorsMap((prev) => [...prev, send_status]);
        addError("sendData", "We are creating your event...", "loading")
        


        setSendStatus("loading");
        const event_id = await sendMinterData(minterData);

        if (event_id) {
            
            setSendStatus("success");
            removeError("sendData")
            setEventId(event_id);
            return event_id
           
        } else {

            addError("sendData", "Error sending data! Please, try again later", "error")
            setSendStatus("error");
            return null
        }


    }

    const sendDataToServer = async () => {
       // setErrorIndices((prev) => [...prev, idx]);
       const event_id = await sendDataToCreatePost()

      
       if (!event_id) {
        return
       }


       if (transactionStatus === "success") {
            setPageNumber(pageNumber + 1);
            //setQRCodeLink(`https://lidum.ru${PORT}/checker/?event_id=${event_id}`)

            setQRCodeLink(`https://t.me/${BOT}/${APP_NAME}?startapp=checker-${event_id}`)
            //console.log(qrCodeLink)
            return
       }

       
        try {
            
       
            const transaction_id = await handleCreateTransaction(event_id)
            
            if (!transaction_id) {     
                return
            }
           
           
            //console.log("sendDataToServer transaction_id", transaction_id)
            if (transaction_id) {
                 
                const status = await waitForTransactionSuccess(transaction_id);
               
                if (status === "success") {
                    setPageNumber(pageNumber + 1);
                    setQRCodeLink(`https://t.me/${BOT}/${APP_NAME}?startapp=checker-${event_id}`)
                    return
                    
                } else {
                    console.log("Transaction did not complete successfully.");
                }
            } else {
                console.log("Failed to create transaction.");
            }
        } catch (error) {
            setTransactionStatus("error")
            addError("transaction", "Transaction in progress...", "loading")
            console.log("handleCreateTransaction error", error)
        }



       

        
    }


    
    const MinterSecondPage = () => {

        
       

        return (
            <>
                
            <div className="form-box">
            {/* <PopupMinter status={sendStatus}/> */}
            {/* <PopupTransactionFailed status={isTransactionError} /> */}
                <form className="minter-form" onSubmit={ async (e) => {
                     
                     e.preventDefault()
                     
                    if (!wallet) {
                       
                            await handleConnect();
                        }
                        else {
                           
                            await handleSubmit(e, pageNumber, {
                    start: start,
                    end: end,
                    password: password,
                    subscriptions: subscriptions,


                }
                
                 
                
                )
                
                        }

                    }}>
               
                        
                   <DateTimeBox start={start} end={end} setStart={setStart} setEnd={setEnd} timeZone={timeZone} />

                   {/* <CalendarsBox start={start} end={end} setStart={setStart} setEnd={setEnd} timeZone={timeZone} /> */}

                    {/* <CalendarInput input_id="startDate"
                    error={false}
                    defaultValue={start || ""}
                    setDateTimeInput={setStart} /> */}

                    <TimeZoneSelector timeZone={timeZone} setTimeZone={setTimeZone} />

                    <div className="form-item">
                        <label className="form-item-label">password</label>
                        <input className="form-input with-square" id="password" type="text" name="password" onChange={(e) => {
                            setPassword(e.target.value.toLowerCase())
                            }
                            } value={password ? password : ""} onBlur={() => handleBlurInput("password")} onFocus={() => handleFocusInput("password")}  maxLength={32} />
                    </div>
                    <div className="form-item margin-bottom-32">
                        <label className="form-item-label">subscriptions</label>
                        <SubscriptionsInputBox subscriptions={subscriptions} setSubscriptions={setSubscriptions} errorIndices={errorIndices} addSubscription={addSubscription} removeSubscription={removeSubscription} addNotVerifiedSubscription={addNotVerifiedSubscription} removeNotVerifiedSubscription={removeNotVerifiedSubscription}  replaceSubscription={replaceSubscription} setErrorIndices={setErrorIndices} notVerifiedSubscriptions={notVerifiedSubscriptions} channelDublicateIndices={channelDublicateIndices} channelNotExistIndices={channelNotExistIndices} channelInvalidFormatIndices={channelInvalidFormatIndices} onFocus={onSubscriptionFocus} successSubscriptions={successSubscriptions} channelAvatars={channelAvatars}/>
                       
                        {/* <input id="subscriptions" className={subscriptions !== initial_subscriptions ? "form-input with-square" : "form-input with-square subscriptions_initial"} onChange={(e) => setSubscriptions(e.target.value)} type="text" name="subscriptions" onFocus={handleFocusSubscritions} onBlur={handleBlurSubscritions} value={subscriptions} required maxLength={256} /> */}
                    </div>

                  
                    
                    {/* <NumberSelector setInvite={setInvite} defaultValue={invite} /> */}
                    
                    <p className="nft-text">{description ? description : ""}</p>
                    <div className="form-input comission-box">
                        <div className="comission-box-label">comission</div>
                        <div>
                            <p className="comission-ton">{comission} TON</p>
                            <p className="comission-usd">&asymp;$ {comissionToUSD}</p>
                        </div>
                    </div>
                    <div className="arrow-box">
                        <ArrowBox pageNumber={2} />
                    </div>
                </form>
            </div>
            </>

        )
    }

    const pagesArr = ["/", MinterFirstPage, MinterSecondPage, MinterThirdPage]



    const BackArrowButton = ({ pageNumber }) => {

        
        if (pageNumber === 1) {
            return (
                <div className="arrow arrow-back no-select" onClick={() => {
                    setIsModalOpen(true)
                }
                }>
                </div>          
            )
        }
        return (
            <div className="arrow arrow-back no-select" onClick={() => {
                setPageNumber(pageNumber - 1)
            }
            }>
            </div>
        )

    }

    const NextArrowButton = ({ pageNumber }) => {

        if (pageNumber >= pagesArr.length - 1) {
            return (
                <div></div>
            )
        }
        // if (pageNumber === 2) {

        //     return (
        //         <div className="arrow arrow-next" value=""  onBlur={()=> alert(1)} onClick={()=> alert(1)} onFocus={()=> alert(1)}  />
        //     )
           
        // }
        return (
            <input className="arrow arrow-next no-select" type="submit" value="" 

            // onFocus={()=> alert(1)}
            // onFocus={ async (e) => {
                     
            //          e.preventDefault()
            //          console.log("onSubmit 2 1")
            //         if (!wallet) {
                       
            //                 await handleConnect();
            //             }
            //             else {
            //                 console.log("onSubmit 2")
            //                 await handleSubmit(e, pageNumber, {
            //         start: start,
            //         end: end,
            //         password: password,
            //         subscriptions: subscriptions,


            //     }
                
                 
                
            //     )
                
            //             }

            //         }}



                    />

            // onClick={() => {
            //     setPageNumber(pageNumber + 1)
            //     }}
        )

    }

    useEffect(() => {
        const fetchAuthorInfo = async () => {
            if (wallet) {
                const author_info = await getAuthorInfo(telegramId, username)
               
                if (author_info && author_info.collection_name) {
                    setName(author_info.collection_name)
                    setReadOnly(true)
                    initialValues.name = author_info.collection_name
                }
            }
        };

        fetchAuthorInfo(); // Вызов функции для получения информации о пользователе
    }, [wallet, tonConnectUI]);

    if (!wallet) return (
        <>
        
            <IfWalletNotConnected wallet={wallet} message={"Connect your wallet to create a new event"} />
            <div className="arrow arrow-back no-select" onClick={() => {
                    navigate('/')
                }
                }>
                </div>     
        </>
    )

    if (eventStatus) return (
        <>
            
             <NoAccessPage message={eventStatus} />
             <div className="arrow arrow-back no-select" onClick={() => {
                    navigate('/')
                }
                }>
                </div>          
            

        </>
    )
    
    return (
        
        <div className="minter-box">
  
     
            <WarningModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/> 
            {pagesArr[pageNumber](pagesArr)}
            <TonConnectButton className='tonConnectButton' style={wallet ? {display: "none"} : {display: "block"}}/>
           
        </div>
    )

}
