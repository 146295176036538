import { formattedDatetime } from "../../utils/appUtils"


export const EventPreview = ({eventParams}) => {


    return (
        <div className="collection_description_container">
            <div className="collection_image_container info">
                <img src={eventParams.logo} alt="Collection" className="img" id="img" />
            </div>
            <div className="collection_summary">
                <h1 className="collection_title">{eventParams.collection_name}</h1>
                <div className="collection_description">{eventParams.event_name}</div>                 
                
            </div>
       
         </div>
    )
}

